import React from "react";
import './Stack.css';

import danielhebaJPG from './images/danielheba-software.jpg';
import rekrutacjaSokratesJPG from './images/rekrutacjaSokrates.jpg';
import mmMaturaJPG from './images/miedzynarodowamatura.jpg';
import arkadJPG from './images/arkad.jpg';
import owitJPG from './images/owit.jpg';
import scwewJPG from './images/scwew.jpg';


const Stack = () => (
<section id="portfolio" className="portfolio py-5">
        <div className="container py-5">
          <p className="h2 mb-0">Stack technologiczny (ostatnie realizacje):</p>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5">
            <div className="col">
              <div className="card h-100">
                <img src={danielhebaJPG} className="card-img-top" alt="Screen projektu akad software" />
                <div className="card-body">
                  <h4 className="card-title"><b>Strona akad.software</b> - informacyjna wizytówka zachęcające Państwa do kontaktu</h4>
                  <p className="text-end mb-5 mt-3 me-3">Zobacz w: <a href="https://github.com/danielheba/akad-software" target="_blank" rel="noreferrer"><i className="fab fa-github"></i></a></p>
                  <p className="card-text">Zastosowano:<ul><li>React: JSX, CRA, RRD v.6</li><li>CSS v.3, Bootstrap v.5</li></ul></p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100">
                <img src={scwewJPG} className="card-img-top" alt="Screen projektu theGift" />
                <div className="card-body">
                  <h4 className="card-title"><b>Serwis internetowy</b> dla klienta zewnętrznego</h4>
                  <p className="text-end mb-5 mt-3 me-3">Zobacz w: <a href="http://www.scwew.bydgoszcz.pl/" target="_blank" rel="noreferrer"><i className="fab fa-internet-explorer"></i></a></p>
                  <p className="card-text">Strona internetowa bydgoskiego Specjalistycznego Centrum Wspierającego Edukację Włączającą.</p>
                  <p className="card-text">Zastosowano:<ul><li>CMS (Wordpress / Elementor)</li></ul></p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100">
                <img src={arkadJPG} className="card-img-top" alt="Screen ze strony platforma-przetargowa.pl" />
                <div className="card-body">
                  <h4 className="card-title"><b>Komercyjny serwis internetowy</b> dla klienta zewnętrznego</h4>
                  <p className="text-end mb-5 mt-3 me-3">Zobacz w: <a href="http://platforma-przetargowa.pl/" target="_blank" rel="noreferrer"><i className="fab fa-internet-explorer"></i></a></p>
                  <p className="card-text">Aplikacja sieciowa do przeprowadzania procedur przetargowych zamówień unijnych jak i krajowych - przejęcie projektu.</p>
                  <p className="card-text">Zastosowano:<ul><li>React: JSX, Redux, RRD v.5, </li><li>CSS v.3, Bootstrap v.5, MUI v.4</li>
                    <li>CMS (Wordpress / Astra / Elementor)</li><li>PHP (Laravel, SQL: Postgre)</li></ul></p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100">
                <img src={owitJPG} className="card-img-top" alt="Screen ze strony owit.bydgoszcz.pl" />
                <div className="card-body">
                  <h4 className="card-title"><b>Serwis internetowy</b> dla klienta zewnętrznego</h4>
                  <p className="text-end mb-5 mt-3 me-3">Zobacz w: <a href="http://www.owit.bydgoszcz.pl/" target="_blank" rel="noreferrer"><i className="fab fa-internet-explorer"></i></a></p>
                  <p className="card-text">Serwis projektu PFRON wspomagający funkcjonowanie osób niepełnosprawnych.</p>
                  <p className="card-text">Zastosowano:<ul><li>CMS (Wordpress / Elementor)</li><li>Technologię Responsive Voice</li></ul></p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100">
                <img src={mmMaturaJPG} className="card-img-top" alt="Screen ze strony Międzynarodowa" />
                <div className="card-body">
                  <h4 className="card-title"><b>Komercyjny serwis internetowy</b> dla klienta zewnętrznego</h4>
                  <p className="text-end mb-5 mt-3 me-3">Zobacz w: <a href="http://miedzynarodowamatura.pl/" target="_blank" rel="noreferrer"><i className="fab fa-internet-explorer"></i></a></p>
                  <p className="card-text">Serwis internetowy dla klienta zewnętrznego propagujący edukację za granicą.</p>
                  <p className="card-text">Zastosowano:<ul><li>CMS (Wordpress / Elementor)</li><li>HTML 5, CSS v.3</li><li>Javascipt</li><li>PHP (SQL: MySQL)</li></ul></p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100">
                <img src={rekrutacjaSokratesJPG} className="card-img-top" alt="Screen rekrutacji online TM Toruń" />
                <div className="card-body">
                  <h4 className="card-title"><b>Skrypt aplikacji</b> dla klienta zewnętrznego</h4>
                  <p className="text-end mb-5 mt-3 me-3">Zobacz w: <a href="https://technikummenedzerskie.pl/rekrutacja/" target="_blank" rel="noreferrer"><i className="fab fa-internet-explorer"></i></a></p>
                  <p className="card-text">Oprogramowanie formularza rekrutacji uczniów dla sieci szkół w woj. kujawsko-pomorskim. Link stanowi przykład zastsowania skryptu użytego w kilku szkołach klienta.</p>
                  <p className="card-text">Zastosowano:<ul><li>CMS (Wordpress / Jupiter X)</li><li>HTML 5, CSS v.3</li><li>Javascipt / jQuery</li><li>PHP</li></ul></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

);

export default Stack;