import React from "react";
import "./Logo.css"; 


const Logo = () => (
    // <a className="navbar-brand px-1" href="#">
    <span className="navbar-brand px-1">   
        <div className="akad letter letter_A1">a</div>
        <div className="akad letter letter_K">k</div>
        <div className="akad letter letter_A2">a</div>
        <div className="akad letter letter_D">d</div>  
            
        <div className="domain_text">.software</div>
    </span>
);

export default Logo;