import React from "react";
import './Contact.css';



const Contact = () => (   
<>
<div className="container contact bg-light text-center">
  <div className="row align-items-start">
    <div className="col bg-danger text-white">
    <p><span className="navbar-brand fontsizeplus">akad.software</span>
                  <br />Daniel Heba' Software House</p>
    </div>

    <span className="text-nowrap">ul. Łobżenicka 14 lok.18 &nbsp; 85-420 Bydgoszcz</span>   
                    <br /><span className="text-nowrap">NIP: 953-190-63-47 &nbsp; REGON: 092459050</span>
                  <br /><span className="text-nowrap">tel. <a href="tel:+48884787767">(+48) 884 787 767</a>
                  <br />e-mail: akad.software@danielheba.com</span> 
  </div>
</div>


</>
);

export default Contact;

